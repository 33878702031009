@use "./config/" as *;
@forward "./main.scss";
.waf-error-page {
    @include background(var(--secondary-1000), "cssimages/error/error-bg.png", center/cover no-repeat);
    @extend .full-width;
    @extend %p-10-3;
    .layout-wrapper {
        @include background(var(--secondary-1000), "cssimages/error/error-section-bg-web.png", center/cover no-repeat);
        @extend %secondary-800-bg-3;
        @extend %half-radius;
        @extend %p-5;
    }
    .title {
        line-height: 3.7rem;
        @extend %font-50-pb;
        @extend %primary-500;
        @extend %mb-5;
    }
    .sub-text {
        @extend %font-18-pr;
        @extend %neutral-50;
        @extend %mb-6;
    }
    .error-content {
        line-height: 2.8rem;
        @extend %font-14-pr;
        @extend %neutral-50;
    }
    .error-content:not(:last-child) {
        @extend %mb-5;
    }
    .link {
        line-height: 2.8rem;
        text-decoration: underline;
        @extend %font-14-pr;
        @extend %primary-500;
    }
    .content {
        @extend %text-center;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-error-page {
        .layout-wrapper {
            padding: var(--space-10);
            align-items: center;
            @include grid(2, var(--space-2));
        }
        .content {
            text-align: left;
            order: -1;
        }
        .title {
            font-size: 7rem;
        }
        .sub-text {
            font-size: 2rem;
        }
        .error-content {
            font-size: 1.4rem;
        }
    }
}